<template>
  <div class="nav-margin">
    <navigation :updatedAccountLogo="updatedAccountLogo"></navigation>
    <div>
      <b-tabs
          active-nav-item-class="font-weight-normal border-bottom border-success border-left-0 border-right-0 border-top-0 text-success"
          active-tab-class="text-success"
          nav-class="bg-white pt-0 d-flex"
          content-class="container-fluid px-0"
          justified
      >
        <b-tab title="Organization" active>
          <template #title>
            <span class="font-weight-normal w-50">Organization</span>
          </template>
          <accountInformation v-on:uploadAccountLogo="updateAccountLogo($event)"/>
        </b-tab>
        <b-tab title="Branding">
          <template #title>
            <span class="font-weight-normal">Branding</span>
          </template>
          <reviewScreenVersions />
          <styleEditor/>
          <accountLabels/>
        </b-tab>
        <b-tab title="Properties">
          <template #title>
            <span class="font-weight-normal">Properties</span>
          </template>
          <div>
            <accountProperties/>
          </div>
        </b-tab>
        <b-tab title="Zapier & API">
          <template #title>
            <span class="font-weight-normal">Zapier & API</span>
          </template>
          <div>
            <zapierConnections/>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import accountInformation from "../components/settings/setting-accountInformation.vue";
import accountUsers from "../components/settings/setting-accountUsers.vue";
import accountChecklists from "../components/settings/setting-accountChecklists.vue";
import zapierConnections from "../components/settings/setting-zapierConnections.vue";
import reviewScreenVersions from "../components/settings/setting-reviewScreenVersions.vue";
import styleEditor from "../components/settings/setting-styleEditor.vue";
import modalDeleteItem from "../components/modals/modal-confirmation.vue";
import accountLabels from "../components/settings/setting-accountLabels";
import accountProperties from "../components/settings/setting-accountProperties";
import { updateFaviconAndTitle } from "../utils/helpers"

export default {
  components: {
    accountInformation,
    accountUsers,
    accountProperties,
    accountChecklists,
    modalDeleteItem,
    zapierConnections,
    reviewScreenVersions,
    styleEditor,
    accountLabels,
  },
  data() {
    return {
      updatedAccountLogo: null
    }
  },
  async mounted() {
    await this.$A.GetCurAccountData();
  },
  methods: {
    updateAccountLogo(logo) {
      updateFaviconAndTitle({ accountLogo: logo });

      this.updatedAccountLogo = logo;
    }
  }
};
</script>

<style scoped>

::v-deep .nav-tabs .nav-link {
  margin-bottom: -2px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

::v-deep .nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 100px;
  padding-right: 100px;
  margin-bottom: 0;
  list-style: none;
}

::v-deep .nav > li > a {
  position: relative;
  display: block;
  padding: 19px 15px;
  font-weight: bold;
  color: #444444;
}

@media (max-width: 750px) {
  ::v-deep .nav {
    display: flex;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
    list-style: none;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media (min-width: 1500px)  and (max-width: 1700px) {
  ::v-deep .nav {
    display: flex;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
    list-style: none;
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
}

@media (min-width: 1700px) and (max-width: 1998px) {
  ::v-deep .nav {
    display: flex;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
    list-style: none;
    padding-left: 300px !important;
    padding-right: 300px !important;
  }
}

@media (min-width: 1998px) and (max-width: 2194px) {
  ::v-deep .nav {
    display: flex;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
    list-style: none;
    padding-left: 500px !important;
    padding-right: 500px !important;
  }
}

@media (min-width: 2194px) and (max-width: 4000px) {
  ::v-deep .nav {
    display: flex;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
    list-style: none;
    padding-left: 600px !important;
    padding-right: 600px !important;
  }
}


</style>
